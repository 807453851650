<template>
  <base-mobile-list class="employees-mobile-list" :items="items">
    <template v-slot:item="{ item }">
      <div>
        <v-btn
          class="employees-mobile-list__full-name text-body-1 font-weight-medium px-0 text-none"
          text
          @click="showDetailInfo(item)"
        >
          {{ item.name }}
        </v-btn>
      </div>

      <div>
        <v-row no-gutters>
          <v-col cols="12" class="pr-1">
            <view-list-field :label="$t('employees.credentials')">
              {{ item.accessLevels }}
            </view-list-field>
          </v-col>
        </v-row>
      </div>
    </template>
  </base-mobile-list>
</template>

<script>
// Components
import BaseMobileList from '@/components/BaseMobileList.vue';
import ViewListField from '@/components/ViewListField.vue';

export default {
  name: 'RolesMobileList',

  components: { BaseMobileList, ViewListField },

  props: {
    items: { type: Array, required: true },
  },

  methods: {
    showDetailInfo(item) {
      this.$emit('open', item);
    },
  },
};
</script>

<style lang="scss">
.employees-mobile-list {
  .v-btn__content {
    justify-content: start;
  }

  &__full-name {
    color: $--primary-color !important;
  }

  &__checkbox {
    width: 24px;
    min-width: 24px;
    height: 24px;
    min-height: 24px;
  }
}
</style>
