<template>
  <base-missing-data :icon="$options.IconMissingDataDefault" :message="message" />
</template>

<script>
import BaseMissingData from '@/components/BaseMissingData.vue';

import IconMissingDataDefault from '@/components/MissingDataIcons/MissingDataDefault.vue';

export default {
  name: 'EmployeesMissingData',

  components: { BaseMissingData },

  computed: {
    message() {
      return this.$t('role.missing_message');
    },
  },

  IconMissingDataDefault,
};
</script>
